@font-face {
  font-family: "Avenir";
  src: url("../src/fonts/Avenir.ttc") format("opentype");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html {
  scroll-behavior: smooth;
  font-family: "Avenir";
}

@media (prefers-reduced-motion: no-preference) {

  html {
    scroll-behavior: auto;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slick-slide > div {
  margin: 0px 20px;
 
}

.slick-track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.slick-list {
  padding: 0 -500px;
}

.slick-slider {
  overflow: hidden;
}

.slick-dots {
  position: relative;
  margin-top: 20px;
  bottom: 0;
}

li.slick-dots {
  color: white !important;
  height: 20px;
}

li.slick-active > button::before {
  color: #00eaff !important;
  font-size: 1rem !important;
}

li > button::before {
  color: #ffffff !important;
  font-size: 1rem !important;
}

@media only screen and (min-width: 200px) {
  .page_header {
    height: 100px;
    align-items: center;
    display: grid;
  }

  .menu_empatica {
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    color: #101734;
    font-family: "Avenir";
    letter-spacing: 2px;
  }

  .alianzas_container {
    display: none;
  }

  .alianzas_container_mobile {
    background-image: url("../assets/BANNER_ALIANZAS/FONDO-SECCIÓN-ALIANZAS.webp");
    width: 100%;
    height: 80vh;
    margin-top: -10px;
    position: relative;
  }

  .img_logo_alianza {
    width: 100%;
    height: auto;
  }

  .transaction_container {
    background-image: url("../assets/TRANSACCIONES/BANNER-TRANSACCIONES.webp");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
    margin-top: -10px;
  }

  .button_transaction {
    border-radius: 25px;
    border-style: solid white;
    background-color: #191919;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(255, 255, 255, 0.7) 9px 6px 20px 2px inset,
      rgba(70, 70, 70, 0.1) 10px -10px 15px;
    color: #ffffff;
    font-size: 50px;
    width: 100%;
    height: 100px;
  }

  .product_container {
    background-image: url("../assets/PRODUCTOS/Fondo.webp");
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -10px;
  }

  .slider_container {
    border-radius: 30px;
    background: transparent;
    /* box-shadow: rgb(32, 44, 95) 0px 0px 20px 12px inset,
      inset rgb(32, 44, 95) 0px 0px 20px 0px; */
    box-shadow: rgb(37, 125, 141) 0px 0px 20px 12px inset,
      rgb(70 79 119) 0px 0px 20px 0px inset;
    min-height: 440px;
    min-width: 300px;
    height: 500px;
    position: relative;
  }

  .card_slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    background-color: transparent;
    flex-direction: row;
    width: 90%;
    flex: 1;
  }

  .container_name_slider {
    width: 10%;
    flex-direction: row;
    align-items: center;
    font-family: "Avenir";
    font-size: 25px;
    letter-spacing: 10px;
  }

  .container_img_slider {
    width: 90%;
    align-items: center;
    justify-content: start;
    text-align: center;
    flex-direction: row;
  }

  .container_img_slider img {
    object-fit: contain;
    display: block;
    position: absolute;
    top: 40%;
    left: 50%;
    height: 70%;
    width: 70%;
    transform: translate(-50%, -50%);
  }

  .container_btn_slider {
    width: 100%;
    height: 20%;
    position: absolute;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    display: flex;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .button_slide {
    border-radius: 15px;
    background: linear-gradient(
      157deg,
      rgb(255 255 255) 3%,
      rgb(11, 20, 53) 28%
    );
    box-shadow: rgb(32, 44, 95) 1px -1px 11px 0px inset rgb(32, 44, 95) 1px 0px 20px
      0px; /*  este es el bueno */
    color: #ffffff;
    font-size: 20px;
    font-weight: 1px;
    width: 150px;
    height: auto;
    word-wrap: break-word;
  }

  .div_logo_productos {
    position: absolute;
    object-fit: contain;
    display: block;
    left: 75%;
    bottom: 70%;
    width: 50px;
    height: auto;
  }

  .img_logo {
    width: 100%;
  }

  .modal_slider_container {
    background: transparent;
    min-height: 440px;
    min-width: 300px;
    height: 500px;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .modal_card_slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    background-color: transparent;
    flex-direction: row;
    width: 100%;
    flex: 1;
  }

  .modal_container_name_slider {
    position: relative;
    flex-direction: row;
    align-items: center;
    left: 5%;
    font-family: "Avenir";
    font-size: 25px;
    letter-spacing: 10px;
    width: 10%;
  }

  .modal_container_img_slider {
    width: 90%;
    align-items: center;
    justify-content: start;
    text-align: center;
    flex-direction: row;
  }

  .ant-menu-dark .ant-menu-item-selected {
    background-color: transparent;
  }

  .modal_container_img_slider img {
    object-fit: contain;
    display: block;
    position: absolute;
    top: 10%;
    left: 40%;
    height: 60%;
    width: 60%;
  }

  .btn_slick_left {
    position: absolute;
    top: 50%;
    background-color: transparent;
    border: transparent;
    z-index: 10000;
    transform: scale(3);
  }

  .btn_slick_left:hover {
    transform: scale(1.5);
  }

  .btn_slick_right {
    position: absolute;
    top: 50%;
    left: 90%;
    background-color: transparent;
    transform: scale(3);
    border: transparent;
  }

  .btn_slick_right:hover {
    transform: scale(1.5);
  }

  .slick_btn_icons {
    color: white;
  }

  .modal_dots_slider {
    position: relative;
    margin-top: 20px;
    bottom: 0;
  }

  .btn_close_modal {
    background-color: transparent;
    border: transparent;
    position: absolute;
    top: 40%;
    bottom: 0;
    color: white;
    left: -30px;
    transform: scale(1.2);
  }

  .btn_close_modal:hover {
    animation: crescendo 1.5s alternate infinite ease-in;
  }

  .easily_container {
    background-image: url("../assets/BANNER_FACILITAMOS/Facilitamos-fondo.webp");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80vh;
    margin-top: -10px;
  }

  .valores_container {
    display: none;
  }

  .valores_container_mobile {
    background-image: url("../assets/INFO_EMPÁTICA/Movil/Fondo.webp");
    background-size: cover;
  }

  .empatica_logo_container {
    position: relative;
  }

  .container_contacto {
    display: none;
  }

  .container_contacto_mobile {
    background-image: url("../assets/CONTACTO/CONTACTO-BANNER-1.webp");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .logo_empatica {
    width: 100;
    height: auto;
  }

  .modal_description {
    font-family: "Avenir";
    height: 700px;
  }

  .modal_img_producto {
    width: 100%;
    height: 20%;
    position: relative;
    top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal_info_producto {
    top: 20%;
    position: relative;
    width: 100%;
    height: 30%;
  }
}

@media only screen and (min-width: 540px) {
  .page_header {
    height: 100px;
    align-items: center;
    display: grid;
  }

  .menu_empatica {
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    color: #101734;
    font-family: "Avenir";
    letter-spacing: 2px;
  }

  .alianzas_container {
    display: none;
  }

  .alianzas_container_mobile {
    background-image: url("../assets/BANNER_ALIANZAS/FONDO-SECCIÓN-ALIANZAS.webp");
    width: 100%;
    height: 90vh;
    margin-top: -10px;
    position: relative;
  }

  .transaction_container {
    background-image: url("../assets/TRANSACCIONES/BANNER-TRANSACCIONES.webp");
    background-size: cover;
    height: 100vh;
    width: 100%;
  }

  .button_transaction {
    border-radius: 25px;
    border-style: solid white;
    background-color: #191919;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(255, 255, 255, 0.7) 9px 6px 20px 2px inset,
      rgba(70, 70, 70, 0.1) 10px -10px 15px;
    color: #ffffff;
    font-size: 50px;
    width: 100%;
    height: 100px;
  }

  .product_container {
    background-image: url("../assets/PRODUCTOS/Fondo.webp");
    width: 100%;
    height: 110vh;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -10px;
  }

  .slider_container {
    border-radius: 30px;
    background: transparent;
    /* box-shadow: rgb(32, 44, 95) 0px 0px 20px 12px inset,
      inset rgb(32, 44, 95) 0px 0px 20px 0px; */
    box-shadow: rgb(37, 125, 141) 0px 0px 20px 12px inset,
      rgb(70 79 119) 0px 0px 20px 0px inset;
    min-height: 440px;
    min-width: 400px;
    height: 500px;
    position: relative;
  }

  .card_slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    background-color: transparent;
    flex-direction: row;
    width: 90%;
    flex: 1;
  }

  .container_name_slider {
    width: 10%;
    flex-direction: row;
    align-items: center;
    font-size: 25px;
    letter-spacing: 10px;
  }

  .container_img_slider {
    width: 90%;
    align-items: center;
    justify-content: start;
    text-align: center;
    flex-direction: row;
  }

  .container_img_slider img {
    object-fit: contain;
    display: block;
    position: absolute;
    top: 40%;
    left: 50%;
    height: 70%;
    width: 70%;
    transform: translate(-50%, -50%);
  }

  .container_btn_slider {
    width: 100%;
    height: 20%;
    position: absolute;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    display: flex;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .button_slide {
    border-radius: 15px;
    background: linear-gradient(
      157deg,
      rgb(255 255 255) 3%,
      rgb(11, 20, 53) 28%
    );
    box-shadow: rgb(32, 44, 95) 1px -1px 11px 0px inset rgb(32, 44, 95) 1px 0px 20px
      0px; /*  este es el bueno */
    color: #ffffff;
    font-size: 20px;
    font-weight: 1px;
    width: 200px;
    height: 40px;
  }

  .img_logo {
    position: absolute;
    object-fit: contain;
    display: block;
    top: 10%;
    left: 70%;
    width: 20%;
    height: 50%;
    transform: translate(20%, -70%);
  }

  .modal_slider_container {
    background: transparent;
    min-height: 440px;
    min-width: 500px;
    height: 500px;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .modal_card_slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    background-color: transparent;
    flex-direction: row;
    width: 100%;
    flex: 1;
  }

  .modal_container_name_slider {
    position: relative;
    flex-direction: row;
    align-items: center;
    left: 5%;
    font-family: "Avenir";
    font-size: 25px;
    letter-spacing: 10px;
    width: 10%;
  }

  .modal_container_img_slider {
    width: 90%;
    align-items: center;
    justify-content: start;
    text-align: center;
    flex-direction: row;
  }

  .modal_container_img_slider img {
    object-fit: contain;
    display: block;
    position: absolute;
    top: 40%;
    left: 50%;
    height: 60%;
    width: 60%;
    transform: translate(-50%, -50%);
  }

  .btn_slick_left {
    position: absolute;
    top: 50%;
    background-color: transparent;
    border: transparent;
    z-index: 10000;
    transform: scale(3);
  }

  .btn_slick_left:hover {
    transform: scale(1.5);
  }

  .btn_slick_right {
    position: absolute;
    top: 50%;
    left: 90%;
    background-color: transparent;
    transform: scale(3);
    border: transparent;
  }

  .btn_slick_right:hover {
    transform: scale(1.5);
  }

  .slick_btn_icons {
    color: white;
  }

  .modal_dots_slider {
    position: relative;
    margin-top: 20px;
    bottom: 0;
  }

  .btn_close_modal {
    background-color: transparent;
    border: transparent;
    position: absolute;
    top: 40%;
    bottom: 0;
    color: white;
    left: -30px;
    transform: scale(1.2);
  }

  .btn_close_modal:hover {
    animation: crescendo 1.5s alternate infinite ease-in;
  }

  .easily_container {
    background-image: url("../assets/BANNER_FACILITAMOS/Facilitamos-fondo.webp");
    background-size: cover;
    width: 100%;
    height: 80vh;
  }

  .valores_container {
    position: relative;
    background-image: url("../assets/INFO_EMPÁTICA/FONDO-INFO-EMPÁTICA.webp");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 120%;
    background-position: center center;
    min-height: 100%;
  }

  .valores_container_mobile {
    display: none;
  }

  .container_contacto {
    display: none;
  }

  .container_contacto_mobile {
    display: block;
  }

  .empatica_logo_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modal_description {
    font-family: "Avenir";
    height: 500px;
  }

  .modal_img_producto {
    width: 100%;
    height: 30%;
    position: relative;
    top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal_info_producto {
    top: 10%;
    position: relative;
    width: 100%;
    height: 30%;
  }
}

@media only screen and (min-width: 768px) {
  .page_header {
    height: 100px;
    align-items: center;
    display: grid;
  }

  .menu_empatica {
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    color: #101734;
    font-family: "Avenir";
    letter-spacing: 2px;
  }

  .alianzas_container {
    background-image: url("../assets/BANNER_ALIANZAS/FONDO-SECCIÓN-ALIANZAS.webp");
    width: 100%;
    height: 60vh;
    margin-top: -10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .alianzas_container_mobile {
    display: none;
  }

  .transaction_container {
    background-image: url("../assets/TRANSACCIONES/BANNER-TRANSACCIONES.webp");
    background-size: cover;
    width: 100%;
    height: 60vh;
    margin-top: -10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button_transaction {
    border-radius: 25px;
    border-style: solid white;
    background-color: #191919;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(255, 255, 255, 0.7) 9px 6px 20px 2px inset,
      rgba(70, 70, 70, 0.1) 10px -10px 15px;
    color: #ffffff;
    font-size: 50px;
    width: 100%;
    height: 100px;
  }

  .product_container {
    background-image: url("../assets/PRODUCTOS/Fondo.webp");
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -10px;
  }

  .slider_container {
    border-radius: 30px;
    background: transparent;
    /* box-shadow: rgb(32, 44, 95) 0px 0px 20px 12px inset,
      inset rgb(32, 44, 95) 0px 0px 20px 0px; */
    box-shadow: rgb(37, 125, 141) 0px 0px 20px 12px inset,
      rgb(70 79 119) 0px 0px 20px 0px inset;
    min-height: 440px;
    min-width: 600px;
    height: 500px;
    position: relative;
  }

  .card_slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    background-color: transparent;
    flex-direction: row;
    width: 90%;
    flex: 1;
  }

  .container_name_slider {
    width: 10%;
    flex-direction: row;
    align-items: center;
    font-size: 25px;
    letter-spacing: 10px;
  }

  .container_img_slider {
    width: 90%;
    align-items: center;
    justify-content: start;
    text-align: center;
    flex-direction: row;
  }

  .container_img_slider img {
    object-fit: contain;
    display: block;
    position: absolute;
    top: 40%;
    left: 50%;
    height: 70%;
    width: 70%;
    transform: translate(-50%, -50%);
  }

  .container_btn_slider {
    width: 100%;
    height: 20%;
    position: absolute;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    display: flex;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .button_slide {
    border-radius: 15px;
    background: linear-gradient(
      157deg,
      rgb(255 255 255) 3%,
      rgb(11, 20, 53) 28%
    );
    box-shadow: rgb(32, 44, 95) 1px -1px 11px 0px inset rgb(32, 44, 95) 1px 0px 20px
      0px; /*  este es el bueno */
    color: #ffffff;
    font-size: 20px;
    font-weight: 1px;
    width: 200px;
    height: 40px;
  }

  .img_logo {
    position: absolute;
    object-fit: contain;
    display: block;
    top: 10%;
    left: 70%;
    width: 20%;
    height: 50%;
    transform: translate(20%, -70%);
  }

  .modal_slider_container {
    background: transparent;
    min-height: 440px;
    min-width: 500px;
    height: 500px;
    display: flex;
    position: relative;
    justify-content: center;
  }

  .modal_card_slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    background-color: transparent;
    flex-direction: row;
    width: 100%;
    overflow-y: scroll;
    flex: 1;
  }

  .modal_container_name_slider {
    position: relative;
    flex-direction: row;
    align-items: center;
    left: 5%;
    font-family: "Avenir";
    font-size: 25px;
    letter-spacing: 10px;
    width: 10%;
  }

  .modal_container_img_slider {
    width: 90%;
    align-items: center;
    justify-content: start;
    text-align: center;
    flex-direction: row;
  }

  .modal_container_img_slider img {
    object-fit: contain;
    display: block;
    position: absolute;
    top: 40%;
    left: 50%;
    height: 60%;
    width: 60%;
    transform: translate(-50%, -50%);
  }

  .btn_slick_left {
    position: absolute;
    top: 50%;
    background-color: transparent;
    border: transparent;
    z-index: 10000;
    transform: scale(3);
  }

  .btn_slick_left:hover {
    transform: scale(1.5);
  }

  .btn_slick_right {
    position: absolute;
    top: 50%;
    left: 90%;
    background-color: transparent;
    transform: scale(3);
    border: transparent;
  }

  .btn_slick_right:hover {
    transform: scale(1.5);
  }

  .slick_btn_icons {
    color: white;
  }

  .modal_dots_slider {
    position: relative;
    margin-top: 20px;
    bottom: 0;
  }

  .btn_close_modal {
    background-color: transparent;
    border: transparent;
    position: absolute;
    top: 40%;
    bottom: 0;
    color: white;
    left: -30px;
    transform: scale(1.2);
  }

  .btn_close_modal:hover {
    animation: crescendo 1.5s alternate infinite ease-in;
  }

  .easily_container {
    background-image: url("../assets/BANNER_FACILITAMOS/Facilitamos-fondo.webp");
    background-size: cover;
    width: 100%;
    height: 80vh;
  }

  .valores_container {
    position: relative;
    background-image: url("../assets/INFO_EMPÁTICA/FONDO-INFO-EMPÁTICA.webp");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 120%;
    background-position: center center;
    min-height: 100%;
    display: block;
  }

  .valores_container_mobile {
    display: none;
  }

  .container_contacto {
    display: block;
  }

  .container_contacto_mobile {
    display: none;
  }

  .empatica_logo_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modal_description {
    font-family: "Avenir";
    height: 500px;
  }

  .modal_img_producto {
    width: 100%;
    height: 30%;
    position: relative;
    top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal_info_producto {
    top: 10%;
    position: relative;
    width: 100%;
    height: 30%;
  }
}

@media only screen and (min-width: 1200px) {
  .page_header {
    height: 100px;
    align-items: center;
    display: grid;
  }

  .menu_empatica {
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    color: #101734;
    font-family: "Avenir";
    letter-spacing: 2px;
  }

  .alianzas_container {
    background-image: url("../assets/BANNER_ALIANZAS/FONDO-SECCIÓN-ALIANZAS.webp");
    width: 100%;
    height: 100vh;
    margin-top: -10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .alianzas_container_mobile {
    display: none;
  }

  .transaction_container {
    background-image: url("../assets/TRANSACCIONES/BANNER-TRANSACCIONES.webp");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
    margin-top: -10px;
  }

  .button_transaction {
    border-radius: 25px;
    border-style: solid white;
    background-color: #191919;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(255, 255, 255, 0.7) 9px 6px 20px 2px inset,
      rgba(70, 70, 70, 0.1) 10px -10px 15px;
    color: #ffffff;
    font-size: 50px;
    width: 100%;
    height: 100px;
  }

  .product_container {
    background-image: url("../assets/PRODUCTOS/Fondo.webp");
    width: 100%;
    height: 110vh;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top: -10px;
  }

  .slider_container {
    border-radius: 30px;
    background: transparent;
    /* box-shadow: rgb(32, 44, 95) 0px 0px 20px 12px inset,
      inset rgb(32, 44, 95) 0px 0px 20px 0px; */
    box-shadow: rgb(37, 125, 141) 0px 0px 20px 12px inset,
      rgb(70 79 119) 0px 0px 20px 0px inset;
    min-height: 440px;
    min-width: 600px;
    height: 500px;
    position: relative;
  }

  .card_slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    background-color: transparent;
    flex-direction: row;
    width: 90%;
    flex: 1;
  }

  .container_name_slider {
    width: 10%;
    flex-direction: row;
    align-items: center;
    font-size: 25px;
    letter-spacing: 10px;
  }

  .container_img_slider {
    width: 90%;
    align-items: center;
    justify-content: start;
    text-align: center;
    flex-direction: row;
  }

  .container_img_slider img {
    object-fit: contain;
    display: block;
    position: absolute;
    top: 40%;
    left: 50%;
    height: 70%;
    width: 70%;
    transform: translate(-50%, -50%);
  }

  .container_btn_slider {
    width: 100%;
    height: 20%;
    position: absolute;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    display: flex;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .button_slide {
    border-radius: 15px;
    background: linear-gradient(
      157deg,
      rgb(255 255 255) 3%,
      rgb(11, 20, 53) 28%
    );
    box-shadow: rgb(32, 44, 95) 1px -1px 11px 0px inset rgb(32, 44, 95) 1px 0px 20px
      0px; /*  este es el bueno */
    color: #ffffff;
    font-size: 20px;
    font-weight: 1px;
    width: 200px;
    height: 40px;
  }

  .img_logo {
    position: absolute;
    object-fit: contain;
    display: block;
    top: 10%;
    left: 70%;
    width: 20%;
    height: 50%;
    transform: translate(20%, -70%);
  }

  .modal_slider_container {
    background: transparent;
    min-height: 440px;
    min-width: 500px;
    height: 500px;
    position: relative;
    justify-content: center;
    display: flex;
  }

  .modal_card_slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    background-color: transparent;
    flex-direction: row;
    width: 100%;
    flex: 1;
  }

  .modal_container_name_slider {
    position: relative;
    flex-direction: row;
    align-items: center;
    left: 5%;
    font-family: "Avenir";
    font-size: 25px;
    letter-spacing: 10px;
    width: 10%;
  }

  .modal_container_img_slider {
    width: 90%;
    align-items: center;
    justify-content: start;
    text-align: center;
    flex-direction: row;
  }

  .modal_container_img_slider img {
    object-fit: contain;
    display: block;
    position: absolute;
    top: 40%;
    left: 50%;
    height: 60%;
    width: 60%;
    transform: translate(-50%, -50%);
  }

  .btn_slick_left {
    position: absolute;
    top: 50%;
    background-color: transparent;
    border: transparent;
    z-index: 10000;
    transform: scale(3);
  }

  .btn_slick_left:hover {
    transform: scale(1.5);
  }

  .btn_slick_right {
    position: absolute;
    top: 50%;
    left: 90%;
    background-color: transparent;
    transform: scale(3);
    border: transparent;
  }

  .btn_slick_right:hover {
    transform: scale(1.5);
  }

  .slick_btn_icons {
    color: white;
  }

  .modal_dots_slider {
    position: relative;
    margin-top: 20px;
    bottom: 0;
  }

  .btn_close_modal {
    background-color: transparent;
    border: transparent;
    position: absolute;
    top: 40%;
    bottom: 0;
    color: white;
    left: -30px;
    transform: scale(1.2);
  }

  .btn_close_modal:hover {
    animation: crescendo 1.5s alternate infinite ease-in;
  }

  .easily_container {
    background-image: url("../assets/BANNER_FACILITAMOS/Facilitamos-fondo.webp");
    background-size: cover;
    width: 100%;
    height: 80vh;
  }

  .valores_container {
    position: relative;
    background-image: url("../assets/INFO_EMPÁTICA/FONDO-INFO-EMPÁTICA.webp");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 120%;
    background-position: center center;
    min-height: 100%;
    display: block;
  }

  .valores_container_mobile {
    display: none;
  }

  .container_contacto {
    display: block;
  }

  .container_contacto_mobile {
    display: none;
  }

  .empatica_logo_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modal_description {
    font-family: "Avenir";
    height: 500px;
  }

  .modal_img_producto {
    width: 100%;
    height: 30%;
    position: relative;
    top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal_info_producto {
    top: 10%;
    position: relative;
    width: 100%;
    height: 30%;
  }
}

.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;
}

.text_white {
  color: white;
  text-align: center;
  font-size: 20px;
  font-family: "Avenir";
}

.m4 {
  margin-top: 4%;
}

@keyframes crescendo {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(2);
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #96ecfb;
  border-radius: 10px;
}

.video-player {
  width: "100%";
  height: "auto";
  opacity: 1;
  transition: opacity 0.5s ease-out; /* Ajusta la duración y el tipo de transición según tus preferencias */
}

.video-player.hide {
  opacity: 0;
}
